import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useMedusa } from "medusa-react"

interface AdyenMakePaymentPayload {
  paymentMethodData: unknown
  browserInfo: unknown
  isArchive?: boolean
}

interface AdyenAdditionalDetails {
  paymentMethodData: unknown
}

export const useAdyenMakePayment = ({ cartId }: { cartId: string }) => {
  const { client: medusa } = useMedusa()
  const queryClient = useQueryClient()

  return useMutation(
    ["adyen-make-payment", cartId],
    async (payload: AdyenMakePaymentPayload) => {
      const data = await medusa.client.request(
        "POST",
        `/store/carts/${cartId}/adyen-payment`,
        payload
      )

      await queryClient.invalidateQueries([cartId])
      return data
    }
  )
}

export const useAdyenAdditionalDetails = ({ cartId }: { cartId: string }) => {
  const { client: medusa } = useMedusa()
  const queryClient = useQueryClient()

  return useMutation(
    ["adyen-additional-details", cartId],
    async (payload: AdyenAdditionalDetails) => {
      const data = await medusa.client.request(
        "POST",
        `/store/carts/${cartId}/adyen-additional-details`,
        payload
      )

      await queryClient.invalidateQueries([cartId])
      return data
    }
  )
}
